import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import url from "../url ";

export function Connection(props) {
  const [byt, setbut] = useState([])
  const [isLogin, setisLogin] = useState(false)
  const [obj, setObj] = useState({})
  const location = window.location;
  const queryParams = new URLSearchParams(location.search);
  const tokenForKeilot = queryParams.get("tokenForKeilot");


  const navigate = useNavigate();
  useEffect(() => {
  let req =  async()=> {
    try{
      const res = await fetch(`${url}/user/get-user-detils`, {
        method: 'post',
        credentials: 'include',
      });
      const data = await res.json()
      if(data.status === 'fail'){
        navigate('/login' + (tokenForKeilot ? `?tokenForKeilot=${tokenForKeilot}` : '') )
      }
      localStorage.setItem("data", JSON.stringify(data));
      // props.obj = data
      setObj(data)
    }
    catch(e){
      console.log(e);
    }
    setisLogin(true)
  }
  req()
  }, [navigate, tokenForKeilot])

  useEffect(() => {
    const login = ()=>{
      let items = [];
      let cuomte = 0;
      if (Object.keys(obj).length > 0) {
        if (obj.admin) {
          items.push(<button 
            key={'admin'}
            style={{ height: 'auto', backgroundColor: 'white', color: 'black', margin: '10px', }}
            onClick={() => { props.setUser("admin"); navigate ("/admin?table=cards") }} >מנהל
          </button>);
          cuomte++;

        }
        if (obj.stores) {
          items.push(<h3 key="stores-title">חנויות</h3>);
          obj.stores.forEach((store, index) => {
            items.push(<div key={"stores-title" + store.id}>
              <button style={{ height: 'auto', backgroundColor: 'white', color: 'black', margin: '10px', }}
              onClick={() => { props.setUser( store.name); navigate(  `/CardCode/${store.id}/money`)}}
              key={`store-${index}`}>{store.name}</button> 
            </div>);
            cuomte++;
          });
        }
        if (obj.storeManage) {
          items.push(<h3 key="storeManage">ניהול חנויות</h3>);
          obj.storeManage.forEach((store, index) => {
            items.push(<div key={'storeManage' + store.id}>
              <button style={{ height: 'auto', backgroundColor: 'white', color: 'black', margin: '10px', }} 
              onClick={() =>{ props.setUser( store.name);navigate(  `/storeManager/${store.id}?table=card_usage_history`)}} 
              key={`store-${index}`}>{store.name}</button> 
            </div>);
            cuomte++;
          });
        }
        if (obj.clubs) {
          items.push(<h3 key="clubs-title">מועדונים</h3>);
          obj.clubs.forEach((club, index) => {
            items.push(<div key={'club' + club.id}>
              <button style={{ height: 'auto', backgroundColor: 'white', color: 'black', margin: '10px', }} 
              onClick={() =>{ props.setUser( club.name);navigate(`/club/${club.id}?table=cards`) }} 
              key={`club-${index}`}>{club.name}</button> 
            </div>);
            cuomte++;
          });
        }
        if (obj.recruiters) {
          items.push(<h3 key="recruiter">משווקים</h3>);
          obj.recruiters.forEach((recruiter, index) => {
            items.push(<div key={'recruiter' + recruiter.id}>
              <button style={{ height: 'auto', backgroundColor: 'white', color: 'black', margin: '10px', }} 
              onClick={() => { props.setUser( recruiter.name);navigate(`/recruiter/${recruiter.id}?table=report_for_recruiters`) }} 
              key={`recruiter-${index}`}>{recruiter.name}</button> 
            </div>);
            cuomte++;
          });
        }
        setbut(items)
        if (cuomte === 1) {
          if (obj.admin) {
            navigate('/admin?table=cards')
          }
          else if (obj.stores) {
            navigate(`/CardCode/${obj.stores[0].id}/money`)
          }
          else if (obj.storeManage) {
            navigate(`/storeManager/${obj.storeManage[0].id}?table=card_usage_history`)
          }
          else if (obj.clubs) {
            navigate(`/club/${obj.clubs[0].id}?table=cards`)
          } else if (obj.recruiters) {
            navigate(`/recruiter/${obj.recruiters[0].id}?table=report_for_recruiters`)
          }
        }
        else if(cuomte === 0){
          items.push(<p key={"pp"}>ברוך הבא, הרישום שלך לאתר נקלט בהצלחה, ממתין לאישור מנהל. אם הינך מנהל ארגון או עסק ועדיין לא קיבלת אישור כניסה נא פנה לטל 0732379998</p>)
        }
      } else {
        setbut(items)
        items.push(<p key={"pp"}>מתנצלים, יש תקלה בהתחברות,
        <br />
        אם יש למכשיר הגנה מומלץ לבדוק שהקישור <a href="https://apiforserver.kehilotcard.co.il/">הזה</a> פתוח ולא חסום.
        <br />
        או שאפשר להתחבר דרכו אם האתר לא עובד
        <br />
        אם גם זה לא עובד נא לפנות למנהל המערכת
        </p>)
      }
    }
    login()
  }, [obj, navigate, props])


  //   console.log(items);

  return (
    <div style={{ border: '1px black solid', textAlign: 'center', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto' }}>
      {isLogin && <>
      <h2> אפשרויות כניסה </h2>
      {byt}
      </>
      }
      <button onClick={() => {navigate('/login')}}>חזרה</button>
    </div>
  );
}
